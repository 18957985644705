/* 

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */


.typing-demo {
  width: 100%;
  animation: typing 3s steps(22), blink 0.5s step-end infinite alternate;
  white-space: nowrap;
  /* overflow: hidden; */
  border-right: 3px solid;
  font-family: monospace;
  font-size: 2em;
}

@keyframes typing {
  from {
    width: 0;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

@keyframes shine {
  0% {
    left: -100%;
    transition-property: left;
  }
  11.5%, 100% {
    left: 100%;
    transition-property: left;
  }
}

/* Shine effect styling */
.get-insights {
  position: relative;
  overflow: hidden;
  display: inline-flex;
  width: 100%;
  max-width: 200px;
  color: #fff;
  align-items: center;
  border: 1px solid rgba(74, 61, 158, 1);
  justify-content: space-evenly;
  padding: 7px 12px;
  border-radius: 27px;
  background: linear-gradient(to right, rgba(74, 61, 158, 1), rgba(108, 82, 229, 1));
  transition: all 0.3s linear;
}

.get-insights::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(120deg, transparent, rgba(255, 255, 255, 0.4), transparent);
  animation: shine 3.5s infinite;
}

/* Apply btn-anim animation */
.get-insights.btn-anim::before {
  animation: shine 5s ease-in-out infinite;
}

.get-insights:hover {
  border: 1px solid rgba(74, 61, 158, 1);
  background: rgba(0, 158, 247, 0.10);
  color: rgba(108, 82, 229, 1);
}

.get-insights:active,
.get-insights:focus {
  background: #fff;
  border: 2px solid #2f2876;
  color: #2f2876 !important;
}


